import React, { Component } from 'react'

export default class Blog extends Component {
    render() {
        return (
            <div className="pagescroll pt-page pt-page-6 blog">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>MY BLOGS AND NEWS</legend>
          <i className="peicon pe-7s-news-paper" aria-hidden="true" />
          <h3> BLOGS </h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row">
      <div className="col-md-12">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-newspaper-o" aria-hidden="true" /></div>
            <div className="smalltitle">Your Favourite Blog</div>
          </div>
        </div>
      </div>
      <div className="row row-wrapper" id="plan">
        <div className="col-md-4 pricingcol4">
          <h2>Choose your beloved <svg className="heart" viewBox="0 0 32 29.6"><path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                      c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z" /> </svg> </h2>
          <div className="toggle-container cf">
            <div className="switch-toggles">
              <div className="individual active">Personal</div>
              {/* active */}
              <div className="company">Company</div>
            </div>
          </div>
          <center><p>You can visit my personal blog by clicking personal option.<br />Thanks.</p></center>
        </div>
        <div id="price_tables" className="col-md-8 pricingcol8">
          <div className="individual cf active">
            <div className="price-table highlighted">
              <div className="table-inner text-center">
                <div className="blog-card">
                  <div className="card-wrapper" style={{background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)) , url(images/tech-blog-banner.jpg) center/cover no-repeat'}}>
                    <div className="card-data">
                      <div className="card-content">
                        <figure className="snip1204">
                          <div className="author">
                            <img src="images/tech-blog.jpg" alt="sq-sample7" />
                            <h5>Tech Blog</h5><span className="testi-location">Blog</span>
                          </div>
                        </figure> 
                        <div className="cardtag">
                          <span className="label ">Technology</span>
                          <span className="label ">Development</span>
                        </div>
                        <h2> NAYAN BLOG</h2>
                        <a className="btn btn-success" target="_blank" href="https://www.trnayan.com/blog">Know more</a>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://trnayan.com/blog", "_blank")}>
                    <span className="fa fa-send" aria-hidden="true" /> FLY ME TO BLOG
                  </button>
                </div>                   
              </div>
            </div>
            <div className="price-table">
              <div className="table-inner text-center">
                <div className="blog-card">
                  <div className="card-wrapper" style={{background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)) , url(images/nayaneralo-banner.jpg) center/cover no-repeat'}}>
                    <div className="card-data">
                      <div className="card-content">
                        <figure className="snip1204">
                          <div className="author">
                            <img src="images/nayaneralo.png" alt="sq-sample7" />
                            <h5>nayaneralo</h5><span className="testi-location">Bangla Blog</span>
                          </div>
                        </figure> 
                        <div className="cardtag">
                          <span className="label ">Story</span>
                          <span className="label ">Poem</span>
                        </div>
                        <h2> NAYANERALO </h2>
                        <a className="btn btn-success" target="_blank" href="https://nayaneralo.blogspot.com">Know more</a>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://nayaneralo.blogspot.com", "_blank")}>
                    <span className="fa fa-send" aria-hidden="true" />FLY ME TO nayaneralo
                  </button>
                </div>                   
              </div>
            </div>
          </div>
          {/* /Individual */}
          <div className="company cf">
            <div className="price-table highlighted">
              <div className="table-inner text-center">
                <div className="blog-card">
                  <div className="card-wrapper" style={{background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)) , url(images/iqsa-news-banner.jpg) center/cover no-repeat'}}>
                    <div className="card-data">
                      <div className="card-content">
                        <figure className="snip1204">
                          <div className="author">
                            <img src="images/iqsa-news.png" alt="sq-sample7" />
                            <h5>IQSA NEWS</h5><span className="testi-location">News</span>
                          </div>
                        </figure> 
                        <div className="cardtag">
                          <span className="label ">Technology</span>
                          <span className="label ">News</span>
                        </div>
                        <h2>IQSA NEWS</h2>
                        <a className="btn btn-success" target="_blank" href="https://www.iqsanews.com">Know more</a>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://www.iqsanews.com", "_blank")}>
                    <span className="fa fa-send" aria-hidden="true" />FLY ME TO IQSA News
                  </button>
                </div>                   
              </div>
            </div>
            <div className="price-table">
              <div className="table-inner text-center">
                <div className="blog-card">
                  <div className="card-wrapper" style={{background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)) , url(images/medium-banner.jpg) center/cover no-repeat'}}>
                    <div className="card-data">
                      <div className="card-content">
                        <figure className="snip1204">
                          <div className="author">
                            <img src="images/medium-logo.png" alt="sq-sample7" />
                            <h5>Medium</h5><span className="testi-location">Blog</span>
                          </div>
                        </figure> 
                        <div className="cardtag">
                          <span className="label ">Technology</span>
                          <span className="label ">Development</span>
                        </div>
                        <h2> MEDIUM</h2>
                        <a className="btn btn-success" target='_blank' href="https://medium.com">Know more</a>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://medium.com", "_blank")} >
                    <span className="fa fa-send" aria-hidden="true" />FLY ME TO BLOG
                  </button>
                </div>                   
              </div>
            </div>
          </div>
          {/* /Company */}
        </div>
      </div>      
      <div className="contact-cta-section contact-cta-section-type2">
        <div className="wrapper-full">
          <div className="cta-wrapper">
            <div className="details-wrapper">
              <h2>Let's create a game-changing experience</h2>
              <p>Think - Learn - Walk </p>
            </div>
            <a className="cta-button" href="https://facebook.com/tr.nayan.bd" target="_blank">Connect with me!</a>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        )
    }
}
