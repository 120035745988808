import React, { Component } from 'react'
export default class Availability extends Component {
    render() {
        return (
            <div className="pagescroll pt-page pt-page-5 availability">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>MY CALENDAR</legend>
          <i className="peicon pe-7s-note2" aria-hidden="true" />
          <h3>AVAILABILITY</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row row-wrapper">
      <div className="col-md-4 calendarcol4">
        <h1 className="calendar-title">Availability Calendar</h1>
        <p>
          Update with a daily task.
        </p>
        <div className="legend">
          <span className="entry red"><i className="fa fa-circle" aria-hidden="true" />Work</span>
          <span className="entry blue"><i className="fa fa-circle" aria-hidden="true" />Sports</span>
          <span className="entry yellow"><i className="fa fa-circle" aria-hidden="true" />Home</span>
          <span className="entry green"><i className="fa fa-circle" aria-hidden="true" />Other</span>
        </div>
      </div>
      <div className="col-md-8 calendarcol8 ">
       
      </div>
    </div>
  </div>
</div>

        )
    }
}
