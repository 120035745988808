import React, { Component } from 'react'

export default class Publications extends Component {
    render() {
        return (
            <div className="pagescroll pt-page pt-page-9 publications">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>MORE</legend>
          <i className="peicon pe-7s-more" aria-hidden="true" />
          <h3>PUBLICATIONS</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row">
      <h2>Updating Soon</h2>
      {/* <div class="col-md-12 ">
                      <h2>2020</h2>
                      <hr>
                      <blockquote>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                          <footer>Someone famous in
                              <cite title="Source Title">Source Title</cite>
                              <button type="button" class="btn btn-link">Link</button>
                          </footer>
                      </blockquote>
                      <blockquote class="blockquote-reverse">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                          <footer>Someone famous in
                              <cite title="Source Title">Source Title</cite>
                              <button type="button" class="btn btn-link">Link</button>
                          </footer>
                      </blockquote>
                      <hr>
                  </div> */}
    </div>
  </div>
</div>

        )
    }
}
