import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        return (
           <div className="pagescroll pt-page pt-page-8 contact contact-section">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>Feel Free to Talk</legend>
          <i className="peicon pe-7s-tools" aria-hidden="true" />
          <h3> CONTACT</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row">
      <div className="col-md-7">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"> <i className="fa fa-heart" /></div>
            <div className="smalltitle">Drop Your Comment</div>
          </div>
        </div>
        {/* Contact Form */}
        <fieldset id="contact_form">
          <div id="result" />
          <input type="text" name="name" id="name" placeholder="NAME" />
          <input type="email" name="email" id="email" placeholder="EMAIL" />
          <textarea name="message" id="message" placeholder="MESSAGE" defaultValue={""} />
          <span className="btn-default btn btn-sm" >SEND MESSAGE</span>
        </fieldset>
        {/* End Contact Form */}
      </div>
      <div className="col-md-5 backgroundmap">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"> <i className="fa fa-heart" /></div>
            <div className="smalltitle">Let's Get In touch</div>
          </div>
        </div>
        <div className="contactinfo">
          <i className="peicon pe-7s-home" aria-hidden="true" />
          <div className="contactinfo-detail">
            <h4>ADRESS</h4>
            <span>7/5, Ring Road, Shyamoli, Dhaka-1207</span>
          </div>
        </div>
        <div className="contactinfo">
          <i className="peicon pe-7s-phone" aria-hidden="true" />
          <div className="contactinfo-detail">
            <h4>PHONE</h4>
            <span>(+880) 1754 929 394</span>
          </div>
        </div>
        <div className="contactinfo">
          <i className="peicon pe-7s-mail" aria-hidden="true" />
          <div className="contactinfo-detail">
            <h4>MAIL</h4>
            <span>nayan.djp@gmail.com</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
      </div>
    </div>
  </div>
</div>

        )
    }
}
