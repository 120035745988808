import React, { Component } from 'react'

export default class LeftSidebar extends Component {
    render() {
        return (
            <div>
  <div id="left-sidebar-wrapper" className="pt-trigger-container">
    <nav>
      <div id="stories" />
      <ul className="main-nav">
        <li><a className="pt-trigger" data-animation="21" data-goto="1" id="home"><i className="peicon pe-7s-home" aria-hidden="true" /><span>Home</span></a></li>
        <li><a className="pt-trigger" data-animation={21} data-goto={2} id="about"><i className="peicon pe-7s-id" aria-hidden="true" /><span>About</span></a></li>
        <li><a className="pt-trigger" data-animation={21} data-goto={3} id="services"><i className="peicon pe-7s-tools" aria-hidden="true" /><span>Services</span></a></li>
        <li><a className="pt-trigger" data-animation={21} data-goto={4} id="portfolio"><i className="peicon pe-7s-note2" aria-hidden="true" /><span>Portfolio</span></a></li>
        {/* <li><a className="pt-trigger" data-animation={21} data-goto={5} id="availability"><i className="peicon pe-7s-wristwatch" aria-hidden="true" /><span>Availability</span></a></li> */}
        <li><a className="pt-trigger" data-animation={21} data-goto={5} id="blog"><i className="peicon pe-7s-news-paper" aria-hidden="true" /><span>Blog</span></a></li>
        <li><a href="#"><i className="peicon pe-7s-box1" aria-hidden="true" /><span>More</span></a>
          <ul className="main-nav_level-1 subnav">
            <li><a className="pt-trigger" data-animation={21} data-goto={7} id="publications"><i className="peicon pe-7s-news-paper" aria-hidden="true" /><span>Publications</span></a></li>
            <li><a className="pt-trigger" data-animation={21} data-goto={8} id="servicebox"><i className="peicon pe-7s-film" aria-hidden="true" /><span>Youtube</span></a></li>
            <li><a className="pt-trigger" data-animation={21} data-goto={9} id="skillsbox"><i className="peicon pe-7s-arc" aria-hidden="true" /><span>Skills</span></a></li>
          </ul>
        </li>
        <li><a className="pt-trigger" data-animation={21} data-goto={6} id="contact"><i className="peicon pe-7s-mail" aria-hidden="true" /><span>Contact</span></a></li>
      </ul>
    </nav>
  </div>
</div>

        )
    }
}
