import React, { Component } from 'react'

export default class Portfolio extends Component {
    render() {
        return (
            <div className="pagescroll pt-page pt-page-4 portfolio">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>WHAT I HAVE DO SO FAR</legend>
          <i className="peicon pe-7s-note2" aria-hidden="true" />
          <h3> PORTFOLIO</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row row-wrapper">
      <ul className="portfolio-sorting  col-md-8 col-sm-12">
        <li><a href="#" data-group="all" className="active">All</a></li>
        <li><a href="#" data-group="achievements">Achievements</a></li>
        <li><a href="#" data-group="events">Events</a></li>
        <li><a href="#" data-group="training">Training</a></li>
      </ul>
      <div className="col-md-4 col-sm-12 portfcountcol">
        <div className="portfocount_container ">
          <span>Projects number</span> <span className="pnumbercount">16</span>
        </div>
      </div>
    </div>
    {/*end portfolio sorting */}
    <ul className="portfolio-items list-unstyled row" id="grid">
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;achievements&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio1.jpg" alt="Item 1" className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a className="magnifictrig openBtn" href="#"><i className="pe-7s-note2" /></a>
            <span className="portfoiocard-title">ICONCS 2020</span>
            <p>2nd International Conference on Cyber Security and Computer Science </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">PUBLICATION</span><span className="portfolio-label">CONFERENCE</span>
            <span className="portfolio-date">15-16 Feb 2020</span>
          </div>
        </div>
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;achievements&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio15.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a className="magnifictrig openBtn" href="#"><i className="pe-7s-note2" /></a>
            <span className="portfoiocard-title">Daffodil ICT Carnival 2018</span>
            <p> Great achievements on cyber security contest.</p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">CONTEST </span>
            <span className="portfolio-date">14 Feb 2018</span>
          </div>
        </div>
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;achievements&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio3.png" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Galactic Problem-Solver</span>
            <p>NASA International Space Apps Challenge </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">HACKATHON</span>
            <span className="portfolio-date">28 Apr 2017</span>
          </div>
        </div>
        
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;achievements&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio16.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">NASA SPACE APPS CHALLENGES </span>
            <p>A great achievement as a problem solver </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">ACHIEVEMENT</span>
            <span className="portfolio-date">28 Apr 2017</span>
          </div>
        </div>
       
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;achievements&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio10.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">We can Daffodil Apps Fellowship</span>
            <p>Team nominated as a second runners-up. </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">CONTEST</span>
            <span className="portfolio-date">2 Apr 2016</span>
          </div>
        </div>
        
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;events&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio12.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">ICONCS 2020</span>
            <p>2nd International Conference on Cyber Security and Computer Science </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">CONFERENCE</span>
            <span className="portfolio-date">15-16 FEB 2020</span>
          </div>
        </div>
       
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;events&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio15.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Cyber Security Awareness</span>
            <p>Organized By DIU ISACA Student Group</p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">SEMINAR</span>
            <span className="portfolio-date">31 Oct 2019</span>
          </div>
        </div>
        
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;events&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio3.png" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">DIU App Contest 2017</span>
            <p>Participating DIU App Contest 2017 </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">CONTEST</span>
            <span className="portfolio-date">May 2017</span>
          </div>
        </div>
        
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;events&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio9.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Positive Hack Days</span>
            <p>Participating Positive Hack Days, Daffodil International University </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">IDENTITY</span>
            <span className="portfolio-date">May 2016</span>
          </div>
        </div>
       
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;events&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio13.png" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Workshop "Journey for a Beautiful Life"</span>
            <p>Participating Motivational Workshop "Journey for a Beautiful Life", Daffodil International University </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">WORKSHOP</span>
            <span className="portfolio-date">Jan 2016</span>
          </div>
        </div>
       
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;training&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio10.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Workshop on Insight of Cyber Security Research</span>
            <p>Participating the Workshop on Insight of Cyber Security Research at Daffodil International University </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">IDENTITY</span>
            <span className="portfolio-date">Apr 2017</span>
          </div>
        </div>
       
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;training&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio12.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Basic Cyber Security Course</span>
            <p>Organized by DIU ISACA STUDENT GROUP</p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">COURSE</span>
            <span className="portfolio-date">Mar 2017</span>
          </div>
        </div>
        
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;training&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio16.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Trace Table:A Debugging Tool</span>
            <p>Organized by Daffodil International University-DIU </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">TRAINING</span>
            <span className="portfolio-label">WORKSHOP</span>
            <span className="portfolio-date">Jun 2016</span>
          </div>
        </div>
       
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;identity&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio4.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
          <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Foundation Class</span>
            <p>Attending foundation class 'Art of living' </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">CLASS</span>
            <span className="portfolio-date">Dec 2015</span>
          </div>
        </div>
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;training&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio5.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a className="magnifictrig openBtn" href="#"><i className="pe-7s-note2" /></a>
            <span className="portfoiocard-title">Certificate Computer Engineer</span>
            <p>Prithibi Computer System</p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">COURSE</span>
            <span className="portfolio-date">Jan 2014</span>
          </div>
        </div>
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;training&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio9.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">PHP</span>
            <p>Training from udemy </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">TRAINING</span>
            <span className="portfolio-date">Jul 2018</span>
          </div>
        </div>
        
      </li>
      <li className="portitem col-md-4 col-sm-6 col-xs-12" data-groups="[&quot;training&quot;]">
        <div className="portfolioitem-container">
          <figure className="portfolio-item">
            <a href="#">
              <img src="images/portfolio6.jpg" alt className="img-responsive" />
            </a>
          </figure>
          <div className="portfolio-cardcontent">
            <a href="#" className="openBtngal magnifictrig " data-effect="zoomInLeft"><i className="pe-7s-photo" /></a>
            <span className="portfoiocard-title">Wordpress For Authors</span>
            <p>Training with udemy </p>
          </div>
          <div className="portfolio-cardfooter">
            <span className="portfolio-label">TRAINING</span>
            <span className="portfolio-date">Oct 2018</span>
          </div>
        </div>
       
      </li>
      {/* sizer */}
      <li className="col-md-4 col-sm-6 col-xs-12 shuffle_sizer" />
    </ul>
    {/*end portfolio grid */}
    <div className="row row-wrapper counter-section">
      <div className="col-md-12">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-bar-chart" aria-hidden="true" /></div>
            <div className="smalltitle">Facts</div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-6">
        <div className="col-md-3">
          <span className="counter-number">100+</span>
        </div>
        <div className="col-md-9">
          <i className="peicon pe-7s-coffee" />
          <h4>CUPS OF TEA</h4>
        </div>
      </div>
      <div className="col-md-6 col-sm-6">
        <div className="col-md-3">
          <span className="counter-number">65</span>
        </div>
        <div className="col-md-9">
          <i className="peicon pe-7s-keypad" />
          <h4>PROJECTS COMPLETED</h4>
        </div>
      </div>
      <div className="col-md-6 col-sm-6">
        <div className="col-md-3">
          <span className="counter-number">29</span>
        </div>
        <div className="col-md-9">
          <i className="peicon pe-7s-diskette" />
          <h4>FAILED</h4>
        </div>
      </div>
      <div className="col-md-6 col-sm-6">
        <div className="col-md-3">
          <span className="counter-number">60+</span>
        </div>
        <div className="col-md-9">
          <i className="peicon pe-7s-smile" />
          <h4>SLEEPLESS NIGHT</h4>
        </div>
      </div>
    </div>
  </div>
</div>

        )
    }
}
