import React, { Component } from 'react'

export default class Skills extends Component {
    render() {
        return (
            <div className="pagescroll pt-page pt-page-11 ">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>MORE</legend>
          <i className="peicon pe-7s-arc" aria-hidden="true" />
          <h3> SKILLS</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row row-wrapper">
      <div className="col-md-6">
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-box2" aria-hidden="true" />
            <span className="skills-title">BLOCKCHAIN TECHNOLOGY</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">75%</span>
            <p>Blockchain is a distributed database existing on multiple computers at the same time. Currently, I am working on several sectors to implement the blockchain technology. A journal was published with named “Supply chain with blockchain technology in Bangladesh”. </p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">HEALTH-CARE</span>
            <span className="skills-label">LAND</span>
            <span className="skills-label">CRYPTOCURRENCY</span>
            <span className="skills-label">CERTIFICATE</span>
            <span className="skills-label">SUPPLYCHAIN</span>
            <span className="skills-level">LEVEL : INTERMEDIATE</span>
            <div className="bar-container">
              <div className="bar" style={{width: '75%'}} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-shield" aria-hidden="true" />
            <span className="skills-title">CYBER SECURITY</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">85%</span>
            <p> Ability to use logic and reasoning to identify the strengths and weaknesses of IT systems. A deep understanding of how hackers work and ability to keep up with the fast pace of change in the criminal cyber-underworld.Ability to seek out vulnerabilities in IT infrastructures</p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">Autopsy</span>
            <span className="skills-label">SQL injection</span>
            <span className="skills-label">Penetration test</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '85%'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-6">
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-magnet" aria-hidden="true" />
            <span className="skills-title">DATA STRUCTURES</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">85%</span>
            <p> Have batter knowledge about Data Structures and algorithms. 
              <br /> Arrays: array rotation, array manipulation<br />Linked lists: reversing a linked list, cycle detection<br />Trees: node swapping, BST validation</p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">Arrays</span>
            <span className="skills-label">linked lists</span>
            <span className="skills-label">Trees</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '85%'}} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-server" aria-hidden="true" />
            <span className="skills-title">DATABASE MANAGEMENT</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">80%</span>
            <p> Have batter knowledge about Database Management System with multiple working project. </p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">MySQL</span>
            <span className="skills-label">SQL Server</span>
            <span className="skills-label">Oracle</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '80%'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-vector" aria-hidden="true" />
            <span className="skills-title">PROGRAMMING LANGUAGES</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">80%</span>
            <p> According to work with various types of project, have good knowledge and experience with different types of language.</p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">PHP</span>
            <span className="skills-label">C</span>
            <span className="skills-label">JAVA</span>
            <span className="skills-label">PHYTHON</span>
            <span className="skills-label">JAVASCRIPT</span>
            <span className="skills-label">R</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '80%'}} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-paint-bucket" aria-hidden="true" />
            <span className="skills-title">GRAPHICS DESIGNING</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">80%</span>
            <p>As a graphics designer, have a good knowledge of different types of photoshop tools and a better experience of doing multiple projects. </p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">Adobe Photoshop</span>
            <span className="skills-label">Adobe Illustrator</span>
            <span className="skills-label">Adobe InDesign</span>
            <span className="skills-label">Adobe XD</span>
            <span className="skills-label">Adobe After Effect</span>
            <span className="skills-label">GIMP</span>
            <span className="skills-label">Filmora‎</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '80%'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12">
      <div className="title-container">
        <div className="titlebg">
          <div className="icon-title"><i className="fa fa-television" aria-hidden="true" /></div>
          <div className="smalltitle">Soft SKill</div>
        </div>
      </div>
    </div>
    <div className="buttongroup">
      <center>
        <button type="button" className="btn btn-default">CREATIVITY</button>
        <button type="button" className="btn btn-default">CRITICAL THINKING</button>
        <button type="button" className="btn btn-default">ACTIVE LISTENING</button>
        <button type="button" className="btn btn-default">PROBLEM-SOLVING</button>
        <button type="button" className="btn btn-default">ADAPTABILITY</button>
        <button type="button" className="btn btn-default">TEAMWORK</button>
        <button type="button" className="btn btn-default">COMMUNICATION</button>
        <button type="button" className="btn btn-default">MANAGEMENT</button>
        <button type="button" className="btn btn-default">LEADERSHIP</button>
        <button type="button" className="btn btn-default">ACTIVE LISTENING</button>
        <button type="button" className="btn btn-default">DECISION MAKING</button>
        <button type="button" className="btn btn-default">PUBLIC SPEAKING</button>
        <button type="button" className="btn btn-default">ORGANIZATION</button>
      </center>
    </div>
  </div>
</div>

        )
    }
}
