import React, { Component } from 'react'

export default class RightSidebar extends Component {
    render() {
        return (
            <div>
  <div id="right-sidebar-wrapper">
    <div className="widgets-container">
      <div className="sidebarwidgets">
        <div className="img-profile-container">
          <div className="bio-details">
            <span className="bio-name"> <strong>T&nbsp;R</strong>&nbsp; Nayan</span>
            <span className="bio-more">Developer and businessman</span>
            <span>
              <a target="_blank" href="https://www.facebook.com/tr.n.bd"><i className="fa fa-facebook" /></a>
              <a target="_blank" href="https://www.instagram.com/tr_nayan/"><i className="fa fa-instagram" /></a>
              <a target="_blank" href="https://twitter.com/TR_Nayan"><i className="fa fa-twitter" /></a>
              <a target="_blank" href="https://www.linkedin.com/in/tr-nayan/"><i className="fa fa-linkedin" /></a>
            </span>
          </div>
        </div>
      </div>
      <div className="recent-activity">
        <ul className="activity-title-ul">
          <li className="activity-title-li">
            <span className=" eyebrow-first activity-title">RECENT ACTIVITY</span>
          </li>
        </ul>
        <ul>
          <li>
            <span className="eyebrow">March 26th, 2020</span>
            <h1>LOCKDOWN</h1>
            <p> In Bangladesh, COVID-19 threatens to cause a humanitarian crisis. </p>
          </li>
          <li>
            <span className="eyebrow">March 15th, 2020</span>
            <h1>HOME</h1>
            <p> Travelling Dhaka to Dinajpur. </p>
          </li>
          <li>
            <span className="eyebrow">Jan 15th, 2020</span>
            <h1>MEETING</h1>
            <p>Meeting with client.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
</div>

        )
    }
}
