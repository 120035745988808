import React, { Component } from 'react'

export default class Home extends Component {
    render() {
        return (
           <div className="pt-page pt-page-1 home">
  <div id="fullpage">
    <div className="section one">
      <h1>HELLO, MY NAME IS</h1>
      <h2>T R NAYAN</h2>
    </div>
    <div className="section two">
      <h1>DEVELOPER</h1>
      <h2>BUSINESSMAN</h2>
    </div>
  </div>
</div>

        )
    }
}
