import React, { Component } from 'react'

export default class Youtube extends Component {
    render() {
        return (
           <div className="pagescroll pt-page pt-page-10 service-box">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>Learn free</legend>
          <i className="peicon pe-7s-way" aria-hidden="true" />
          <h3>VIDEO BOX</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row row-wrapper">
      <div className="col-md-4">
        <div className="service-iconbox">
          <div className="o-video">
            <iframe src="https://www.youtube.com/embed/hiM48j_NZOU?modestbranding=1&autohide=1&showinfo=0&controls=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <center> <h5> What You Didn’t Know about Language Barriers </h5></center>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-iconbox">
          <div className="o-video">
            <iframe src="https://www.youtube.com/embed/3HYcLxfbk6M?modestbranding=1&autohide=1&showinfo=0&controls=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <center> <h5> Dare to dream </h5></center>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-iconbox">
          <div className="o-video">
            <iframe src="https://www.youtube.com/embed/xMhZlt-OHEg?modestbranding=1&autohide=1&showinfo=0&controls=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <center> <h5> How to start your speech </h5></center>
        </div>
      </div>
    </div>
    <div className="row row-wrapper">
      <div className="col-md-4">
        <div className="service-iconbox">
          <div className="o-video">
            <iframe src="https://www.youtube.com/embed/ud3brruUDzg?modestbranding=1&autohide=1&showinfo=0&controls=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <center> <h5> How to introduce yourself</h5></center>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-iconbox">
          <div className="o-video">
            <iframe src="https://www.youtube.com/embed/O2ZN0tkpeWI?modestbranding=1&autohide=1&showinfo=0&controls=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <center> <h5> Presentation Skills-Learn with British Council </h5></center>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-iconbox">
          <div className="o-video">
            <iframe src="https://www.youtube.com/embed/kic--jTaGFs?modestbranding=1&autohide=1&showinfo=0&controls=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <center> <h5> Writing Emails-Learn with British Council </h5></center>
        </div>
      </div>
    </div>
  </div>
</div>

        )
    }
}
