import React, { Component } from 'react'
import Home from './../Home';
import About from './../About';
import Services from './../Services';
import Portfolio from './../Portfolio';
import Availability from './../Availability';
import Blog from './../Blog';
import Contact from './../Contact';
import Publications from './../Publications';
import Youtube from './../Youtube';
import Skills from './../Skills';
import Loader from './../Loader';
import RightSidebar from './RightSidebar';
import Buttons from './Buttons';
import LeftSidebar from './LeftSidebar';



export default class Master extends Component {
    render() {
        return (
            <div>
            
            <div id="wrapper" className="pt-wrapper">
            <LeftSidebar/>
            <RightSidebar/>

            <div className="pagewrapper">

            <Buttons/>
            <Home/>
            <About/>
            <Services/>
            <Portfolio/>
           
            <Blog/>
            <Contact/>
            <Publications/>
            <Youtube/>
            <Skills/>
            </div>
            </div> 
            
</div>
      
                     
    

        )
    }
}
