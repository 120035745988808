import React, { Component } from 'react'

export default class Services extends Component {
    render() {
        return (
           <div className="pagescroll pt-page pt-page-3 services">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>WHAT I CAN DO FOR YOU</legend>
          <i className="peicon pe-7s-tools" aria-hidden="true" />
          <h3> SERVICES</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row row-wrapper">
      <div className="col-md-12">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-television" aria-hidden="true" /></div>
            <div className="smalltitle">Services</div>
          </div>
        </div>
      </div>
      <div className="servicecol col-md-4 col-sm-4">
        <div className="service-iconbox">
          <i className="peicon pe-7s-global" />
          <h5>Domain Hosting</h5>
          <p>You can buy domain and hosting for you with cheap rate.Introducing the complete solution for you.
          </p>
        </div>
      </div>
      <div className="servicecol col-md-4 col-sm-4">
        <div className="service-iconbox">
          <i className="peicon pe-7s-phone" />
          <h5> Developpement</h5>
          <p> Need the highest quality software with the lowest failure risk? I can refer you to contact with IQSA Soft.
          </p>
        </div>
      </div>
      <div className="servicecol col-md-4 col-sm-4">
        <div className="service-iconbox">
          <i className="peicon pe-7s-graph1" />
          <h5>Cyber Security</h5>
          <p> Allows you to stay focused on driving your business forward. Provide VAPT solution for your company.
          </p>
        </div>
      </div>
    </div>
    <div className="row service2 row-wrapper image-service">
      <div className="col-md-12">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-television" aria-hidden="true" /></div>
            <div className="smalltitle">Best Solution for you</div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="service-block-container">
          <div className="service-block">
            <div className="service-underlay">
              <span className="service-name">
                Domain-Hosting
              </span>
            </div>
            <span className="service-icon">
              <figure className="snip1204">
                <div className="author">
                  <img onClick={()=> window.open("https://www.iqsahost.com", "_blank")} src="images/iqsa-host.png" alt="iqsahost" />
                </div>
              </figure> 
            </span>
          </div>
        </div>
        <center>
          <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://www.iqsahost.com", "_blank")}>
            <span className="fa fa-send" aria-hidden="true" /> IQSA HOST
          </button>
        </center>
      </div>
      <div className="col-md-3">
        <div className="service-block-container">
          <div className="service-block">
            <div className="service-underlay">
              <span className="service-name">
                Development
              </span>
            </div>
            <span className="service-icon">
              <figure className="snip1204">
                <div className="author">
                  <img onClick={()=> window.open("https://www.iqsasoft.com", "_blank")} src="images/iqsa-soft.png" alt="iqsasoft" />
                </div>
              </figure> 
            </span>
          </div>
        </div>
        <center>
          <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://www.iqsasoft.com", "_blank")}>
            <span className="fa fa-send" aria-hidden="true" /> IQSA SOFT
          </button>
        </center>
      </div>
      <div className="col-md-3">
        <div className="service-block-container">
          <div className="service-block">
            <div className="service-underlay">
              <span className="service-name">
                Cyber Security
              </span>
            </div>
            <span className="service-icon">
              <figure className="snip1204">
                <div className="author">
                  <img onClick={()=> window.open("https://www.bugsbd.com", "_blank")} src="images/bugsbd-logo.jpg" alt="sq-sample7" />
                </div>
              </figure> 
            </span>
          </div>
        </div>
        <center>
          <button type="button" className="btn btn-default btn-sm" onClick={()=> window.open("https://www.bugsbd.com", "_blank")}>
            <span className="fa fa-send" aria-hidden="true" /> BugsBD Limited
          </button>
        </center>
      </div>
      <div className="col-md-3">
        <div className="service-block-container">
          <div className="service-block">
            <div className="service-underlay">
              <span className="service-name">
                Hacking Forum
              </span>
            </div>
            <span className="service-icon">
              <figure className="snip1204">
                <div className="author">
                  <img onClick={()=> window.open("https://forum.sqliwiki.com", "_blank")} src="images/sqliwiki-logo.png" alt="sqliwiki" />
                </div>
              </figure> 
            </span>
          </div>
        </div>
        <center>
          <button type="button" className="btn btn-default btn-sm"  onClick={()=> window.open("https://forum.sqliwiki.com", "_blank")}>
            <span className="fa fa-send" aria-hidden="true" /> SQLiWiKi
          </button>
        </center>
      </div>
    </div>
    <div className="row service2 row-wrapper image-service">
      <div className="col-md-12">
        <div className="image-service-content">
          <h2>Depressed with your life?</h2><br /><h3> Come on! grow up. <br /> Build yourself. Enjoy your life.</h3>
        </div>
      </div>
    </div>
    <div className="contact-cta-section row row-wrapper">
      <div className="wrapper-full col-md-12">
        <div className="cta-wrapper">
          <div className="details-wrapper">
            <h2>Any Question?</h2>
            <p>Feel Free to talk </p>
          </div>
          <a className="cta-button" href="https://facebook.com/tr.nayan.bd" target="_blank">Connect With Me!</a>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  </div>
</div>

        )
    }
}
