import React, { Component } from 'react'

export default class Loader extends Component {
    render() {
        return (
           <div className="preloader">
  <div className="preloader-container">
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </div>
</div>

        )
    }
}
