import React from 'react';
import logo from './logo.svg';

import Master from './pages/MasterPage/Master';
import './assests/css/bootstrap.min.css';
import './assests/css/main.css';
import './assests/css/colors/blue.css';
import './assests/css/transition-animations.css';
import './assests/css/font-awesome.min.css';
import './assests/css/pe-icon-7-stroke.css';
import './assests/css/jquery.mCustomScrollbar.css';
import './assests/css/animate.min.css';
import './assests/css/magnific-popup.css';
import './assests/css/stories.css';
import './assests/css/custom.css';


function App() {
  return (
    <Master/>
  );
}

export default App;
