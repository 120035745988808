import React, { Component } from 'react'

export default class Buttons extends Component {
    render() {
        return (
        <div>    
  <a href="#left-menu-toggle" className="btn btn-link " id="left-menu-toggle"><span className="peicon pe-7s-menu" /></a>
  <a href="#right-menu-toggle" className="btn btn-link " id="right-menu-toggle"><span className="peicon pe-7s-user" /></a>
  </div>
)
    }
}
