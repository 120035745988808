import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div className="pagescroll pt-page pt-page-2 about">
  <div className="featured-background">
    <div className="backgroundimg">
      <div className="headerdes-container">
        <fieldset>
          <legend>STUDIES AND WORKS</legend>
          <i className="peicon pe-7s-id" aria-hidden="true" />
          <h3>ABOUT ME</h3>
        </fieldset>
      </div>
    </div>
  </div>
  <div className="thecontainer">
    <div className="row row-wrapper">
      <div className="col-md-12">
        <h1 className="Profilename">I'm <strong>T R Nayan</strong> </h1>
        <h5 className="profilejob">Developer and businessman</h5>
        <hr />
      </div>
      <div className="aboutcol col-md-6">
        <ul className="personalinfo">
          <li><span className="personalinfo-tag">Address</span><span className="personalinfo-item">7/5, Ring Road, Shyamoli, Dhaka-1207</span></li>
          <li><span className="personalinfo-tag">E-mail</span> <span className="personalinfo-item">nayan.djp@gmail.com</span></li>
          <li><span className="personalinfo-tag">Phone</span><span className="personalinfo-item">(+880) 1754929394</span></li>
          <li><span className="personalinfo-tag">Status</span><span className="personalinfo-item av-freelance">Available For knowledge sharing</span></li>
          <li><span className="personalinfo-tag">availability</span><span className="personalinfo-item"><i>On Vacation</i> till August 31, 2020</span></li>
        </ul>
      </div>
      <div className="aboutcol col-md-6">
        <p /><center>"You only live once, But If you choose it right.<br />Once is enough."<br />✍️</center><p />
      </div>
    </div>
    <div className="row row-wrapper ">
      <div className="col-md-12">
        <hr />
      </div>
    </div>
    <div className="row row-wrapper ">
      <div className="aboutcol col-md-6 col-sm-12">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-user" aria-hidden="true" /></div>
            <div className="smalltitle">Resume</div>
          </div>
        </div>
        <div className="resume-container">
          <ul className="timeline">
            <li className="time-label">
              <span className="bg-light-blue">EDUCATION
              </span>
            </li>
            <li>
              <i className="peicon pe-7s-study" />
              <div className="res-sec-date">
                <span className="firstdate">2019</span>
                <span>2016</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">DHAKA</span></span>
                <h3 className="timeline-header">SOFTWARE ENGINEERING - B.Sc</h3>
                <div className="timeline-body">
                  Daffodil International University<br /> Duration 4 Years (12 Semester) <br /> January 2016 - December 2019 <br />Class of 2020
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-study" />
              <div className="res-sec-date">
                <span className="firstdate">2014</span>
                <span>2012</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">DINAJPUR</span></span>
                <h3 className="timeline-header">Higher Secondary School Certificate - H.S.C</h3>
                <div className="timeline-body">
                  Dinajpur Government College<br /> Group : Science <br /> Duration 2 Years<br /> July 2012 - June 2014 <br /> Class of 2014
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-study" />
              <div className="res-sec-date">
                <span className="firstdate">2012</span>
                <span>2004</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">DINAJPUR</span></span>
                <h3 className="timeline-header">Secondary School Certificate - S.S.C</h3>
                <div className="timeline-body">
                  Saint Philip's High School &amp; College <br /> Group : Science <br /> Duration 8 Years <br /> January 2004 - December 2011 <br />  Class of 2012
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-study" />
              <div className="res-sec-date">
                <span className="firstdate">2003</span>
                <span>1999</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">DINAJPUR</span></span>
                <h3 className="timeline-header">Primary School Education </h3>
                <div className="timeline-body">
                  Nirmol Shishu Biddaloy
                  (Current-Saint Francis Xavier School) Class : Baby To Two <br /> Duration 5 Years 
                </div>
              </div>
            </li>
            <li className="time-label">
              <span className="bg-green">JOBS AND EXPERIENCE
              </span>
            </li>
            <li>
              <i className="peicon pe-7s-monitor" />
              <div className="res-sec-date">
                <span className="firstdate">Present</span>
                <span>2017</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">Dhaka</span></span>
                <h3 className="timeline-header">BugsBD Limited</h3>
                <div className="timeline-body">
                  <b> Cyber Security Specialist </b><br />Responsible for providing security during the development stages of software systems, networks and data centers. Have to search for vulnerabilities and risks in hardware and software and monitor systems for any unusual activities.
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-monitor" />
              <div className="res-sec-date">
                <span className="firstdate">Present</span>
                <span>2019</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">Dhaka</span></span>
                <h3 className="timeline-header">IQSA Soft</h3>
                <div className="timeline-body">
                  <b> Business Development Executive </b><br />To assist the organisations sales and growth efforts by contributing to new customer acquisition.Include: Build contacts with potential clients to create new business opportunities.
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-monitor" />
              <div className="res-sec-date">
                <span className="firstdate">2019</span>
                <span>2017</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">Dhaka</span></span>
                <h3 className="timeline-header">SoftIT Security</h3>
                <div className="timeline-body">
                  <b>Graphics &amp; Wordpress Developer </b>(Part Time)<br />
                  Professional Freelancer within the graphic design and graphic arts. Assembles together images, typography or motion graphics. Also basic coding and infrastructure of the WordPress platform, creating and customizing plugins and themes.
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-monitor" />
              <div className="res-sec-date">
                <span className="firstdate">2019</span>
                <span>2018</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">Dhaka</span></span>
                <h3 className="timeline-header">DIU ISACA Student Group</h3>
                <div className="timeline-body">
                  <b>Secretary</b><br /> Carries out and delegates all the administrative duties that enable the club and its members to function effectively. Provide the main point of contact for people within and outside the club on just about every aspect of the club’s activities.
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-monitor" />
              <div className="res-sec-date">
                <span className="firstdate">2018</span>
                <span>2017</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">Dhaka</span></span>
                <h3 className="timeline-header">DIU ISACA Student Group</h3>
                <div className="timeline-body">
                  <b>Treasurer</b><br /> Responsible for all of club's money, both incoming and outgoing, and needs to keep accurate records to ensure legal compliance.
                </div>
              </div>
            </li>
            <li>
              <i className="peicon pe-7s-monitor" />
              <div className="res-sec-date">
                <span className="firstdate">2017</span>
                <span>2007</span>
              </div>
              <div className="timeline-item">
                <span className="time"><i className="pe-7s-map-marker" /><span className="res-location">Dinajpur</span></span>
                <h3 className="timeline-header">Voirobi Songit Shikkha Kendro </h3>
                <div className="timeline-body">
                  <b>Junior Art Teacher</b><br />Developing students' drawing, coloring, and painting techniques. Providing instruction on the use of media such as graphite and charcoal pencils, as well as oil, acrylic, and watercolor paint.
                </div>
              </div>
            </li>
            <li>
              <a className="downloadcv" target="_blank" href="https://bit.ly/trnayancv"><i className="peicon pe-7s-cloud-download" />
              </a>
              <div className="timeline-item">
                <button type="button" className="btn btn-default btn-sm">
                  <span className="fa fa-download" aria-hidden="true">           
                  </span> DOWNLOAD RESUME
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="aboutcol col-md-6 col-sm-12">
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-diamond" aria-hidden="true" /></div>
            <div className="smalltitle">Skills</div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-box2" aria-hidden="true" />
            <span className="skills-title">BLOCKCHAIN TECHNOLOGY</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">75%</span>
            <p>Blockchain is a distributed database existing on multiple computers at the same time. Currently, I am working on several sectors to implement the blockchain technology. A journal was published with named “Supply chain with blockchain technology in Bangladesh”. </p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">HEALTH-CARE</span>
            <span className="skills-label">LAND</span>
            <span className="skills-label">CRYPTOCURRENCY</span>
            <span className="skills-label">CERTIFICATE</span>
            <span className="skills-label">SUPPLYCHAIN</span>
            <span className="skills-level">LEVEL : INTERMEDIATE</span>
            <div className="bar-container">
              <div className="bar" style={{width: '75%'}} />
            </div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-vector" aria-hidden="true" />
            <span className="skills-title">PROGRAMMING LANGUAGES</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">80%</span>
            <p> According to work with various types of project, have good knowledge and experience with different types of language.</p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">PHP</span>
            <span className="skills-label">C</span>
            <span className="skills-label">JAVA</span>
            <span className="skills-label">PHYTHON</span>
            <span className="skills-label">JAVASCRIPT</span>
            <span className="skills-label">R</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '80%'}} />
            </div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-magnet" aria-hidden="true" />
            <span className="skills-title">DATA STRUCTURES</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">85%</span>
            <p> Have batter knowledge about Data Structures and algorithms. 
              <br /> Arrays: array rotation, array manipulation<br />Linked lists: reversing a linked list, cycle detection<br />Trees: node swapping, BST validation</p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">Arrays</span>
            <span className="skills-label">linked lists</span>
            <span className="skills-label">Trees</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '85%'}} />
            </div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-server" aria-hidden="true" />
            <span className="skills-title">DATABASE MANAGEMENT</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">80%</span>
            <p> Have batter knowledge about Database Management System with multiple working project. </p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">MySQL</span>
            <span className="skills-label">SQL Server</span>
            <span className="skills-label">Oracle</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '80%'}} />
            </div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-shield" aria-hidden="true" />
            <span className="skills-title">CYBER SECURITY</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">85%</span>
            <p> Ability to use logic and reasoning to identify the strengths and weaknesses of IT systems. A deep understanding of how hackers work and ability to keep up with the fast pace of change in the criminal cyber-underworld.Ability to seek out vulnerabilities in IT infrastructures</p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">Autopsy</span>
            <span className="skills-label">SQL injection</span>
            <span className="skills-label">Penetration test</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '85%'}} />
            </div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-paint-bucket" aria-hidden="true" />
            <span className="skills-title">GRAPHICS DESIGNING</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">80%</span>
            <p>As a graphics designer, have a good knowledge of different types of photoshop tools and a better experience of doing multiple projects. </p>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">Adobe Photoshop</span>
            <span className="skills-label">Adobe Illustrator</span>
            <span className="skills-label">Adobe InDesign</span>
            <span className="skills-label">Adobe XD</span>
            <span className="skills-label">Adobe After Effect</span>
            <span className="skills-label">GIMP</span>
            <span className="skills-label">Filmora‎</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '80%'}} />
            </div>
          </div>
        </div>
        <div className="skills-container">
          <figure className="skills-item">
            <i className="peicon pe-7s-leaf" aria-hidden="true" />
            <span className="skills-title">SOFT SKILL</span>
          </figure>
          <div className="skills-cardcontent">
            <span className="skill-percent">90%</span>
          </div>
          <div className="skills-cardfooter">
            <span className="skills-label">CREATIVITY</span>
            <span className="skills-label">CRITICAL THINKING</span>
            <span className="skills-label">ACTIVE LISTENING</span>
            <span className="skills-label">PROBLEM-SOLVING</span>
            <span className="skills-label">ADAPTABILITY</span>
            <span className="skills-label">TEAMWORK</span>
            <span className="skills-label">COMMUNICATION</span>
            <span className="skills-label">MANAGEMENT</span>
            <span className="skills-label">LEADERSHIP</span>
            <span className="skills-label">ACTIVE LISTENING</span>
            <span className="skills-label">DECISION MAKING</span>
            <span className="skills-label">PUBLIC SPEAKING</span>
            <span className="skills-label">ORGANIZATION</span>
            <span className="skills-level">LEVEL : INTERMEDIATE </span>
            <div className="bar-container">
              <div className="bar" style={{width: '90%'}} />
            </div>
          </div>
        </div>
        <div className="title-container">
          <div className="titlebg">
            <div className="icon-title"><i className="fa fa-comments" aria-hidden="true" /></div>
            <div className="smalltitle">Testimonials</div>
          </div>
        </div>
        <figure className="snip1204">
          <blockquote>He has a good skill and knowledge about cyber Security.He is a knowledge seekers of upcoming technology.</blockquote>
          <div className="author">
            <img src="images/bugsbd-logo.jpg" alt="bugsbd_logo" />
            <h5>BugsBD Limited</h5><span className="testi-location">Dhaka</span>
          </div>
        </figure>                            
      </div>
    </div>
  </div>
</div>

        )
    }
}
